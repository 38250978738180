// import storage from "../../Firebase/config";
import React, { useState, useEffect } from "react";
import { darkToast, toast, ToastContainer } from "../../Components/Toast";
import ReactLoading from "react-loading";
import { useHistory, useParams } from "react-router";
import axios from "axios";
import { serverUrl } from "../../Utils/server";
import Sessions from "./Sessions";

// import FontAwesome from "react-fontawesome";

const EditSession = () => {
  const { sessionId } = useParams();
  const history = useHistory();
  const [SessionName, setSessionName] = useState("");
  const [description, setDescription] = useState("");
  const [eventIndex, setEventIndex] = useState(-1);
  const [rewardProfileIndex, setRewardProfileIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [StartTime, setStartTime] = useState("");
  const [EndTime, setEndTime] = useState("");
  const [event, setEvent] = useState([]);
  const [rewardProfiles, setRewardProfiles] = useState([]);
  const [RewardCount, setRewardCount] = useState([]);
  const [SpanTime, setSpanTime] = useState("");

  useEffect(() => {
    getSessionDetails();
    getEvents();
    getRewardProfiles();
  }, []);

  const getSessionDetails = () => {
    setLoading(true);
    const config = {
      method: "post",
      url: `${serverUrl}/sessions/get-session-by-id`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        sessionId: sessionId,
      },
    };
    console.log(config);

    axios(config)
      .then((response) => {
        setSessionName(response.data.SessionName);
        setDescription(response.data.description);
        setEvent(response.data.event);
        setRewardProfileIndex(response.data.rewardProfileIndex);
        // setRewardProfiles(response.data.rewardProfiles);
        setEndDate(response.data.EndDate);
        setStartDate(response.data.StartDate);
        setRewardCount(response.data.RewardCount);
        setStartTime(response.data.StartTime);
        setEndTime(response.data.EndTime);
        setSpanTime(response.data.SpanTime);
      })
      .catch((err) => {
        toast("Failed to fetch Session info", darkToast);
      });
    setLoading(false);
  };

  const getEvents = () => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${serverUrl}/events/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then((response) => {
        setEvent(response.data);
        setLoading(false);
        setEventIndex(0);
      })
      .catch((err) => {
        toast("Couldn't load events. Please refresh.", darkToast);
        setLoading(false);
      });
  };

  const getRewardProfiles = () => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${serverUrl}/rewardprofiles/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then((response) => {
        setRewardProfiles(response.data);
        setLoading(false);
        setRewardProfileIndex(0);
      })
      .catch((err) => {
        toast("Couldn't load reward type. Please refresh.", darkToast);
        setLoading(false);
      });
  };

  let existingSessions = [];
  let filteredSessions = [];
  const getSessions = () => {
    const config = {
      method: "get",
      url: `${serverUrl}/sessions/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };
    axios(config).then((response) => {
      existingSessions = response.data;
      // console.log(existingSessions);
      filteredSessions = existingSessions.map(({ SessionName }) =>
        SessionName.toLowerCase()
      );
      // console.log(filteredSessions);
      // getDataRewards(filteredSessions);
      setLoading(false);
    });
  };
  getSessions();

  const updateSessions = () => {
    if (SessionName === "") {
      return toast("Session name is required", darkToast);
    } else if (description === "") {
      return toast("Description is required", darkToast);
    } else if (StartDate === "") {
      return toast("Start Date is required", darkToast);
    } else if (EndDate === "") {
      return toast("End Date is required", darkToast);
    } else if (eventIndex === -1) {
      return toast("Select a event", darkToast);
    } else if (rewardProfileIndex === -1) {
      return toast("Select a reward Profile", darkToast);
    } else if (StartTime === "") {
      return toast("Start Time is required", darkToast);
    } else if (EndTime === "") {
      return toast("End Time is required", darkToast);
    } else if (RewardCount === "") {
      return toast("Reward Count is required", darkToast);
    } else if (SpanTime === "") {
      return toast("Span Time is required", darkToast);
    } else if (filteredSessions.includes(SessionName.trim().toLowerCase())) {
      return toast("The Session already exists", darkToast);
    } else if (StartDate > EndDate) {
      return toast("Start Date should be less than End Date", darkToast);
    }

    setLoading(true);
    const config = {
      method: "post",
      url: `${serverUrl}/sessions/update`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        sessionId: sessionId,
        query: {
          SessionName: SessionName,
          description: description,
          event: [
            {
              eventId: event[eventIndex]._id,
              EventName: event[eventIndex].EventName,
            },
          ],
          rewardprofile: [
            {
              rewardprofileId: rewardProfiles[rewardProfileIndex]._id,
              rewardprofileName: rewardProfiles[rewardProfileIndex].ProfileName,
              RewardId: rewardProfiles[rewardProfileIndex].RewardId,
              RewardName: rewardProfiles[rewardProfileIndex].RewardName,
              Quantity: rewardProfiles[rewardProfileIndex].Quantity,
              Order: rewardProfiles[rewardProfileIndex].Order,
            },
          ],
          StartDate: StartDate,
          EndDate: EndDate,
          StartTime: StartTime,
          EndTime: EndTime,
          RewardCount: RewardCount,
          SpanTime: SpanTime,
        },
      },
    };
    console.log(config);
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          toast("Session updated!", darkToast);

          setTimeout(() => {
            history.push("/sessions");
            window.location.reload();
          }, 1000);
        }
        setLoading(false);
      })
      .catch(() => {
        toast("Something went wrong!", darkToast);
        setLoading(false);
      });
  };

  // const getSessionDetails = () => {
  //   setLoading(true);
  //   const config = {
  //     method: "post",
  //     url: `${serverUrl}/sessions/get-session-by-id`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `JWT ${localStorage.getItem("token")}`,
  //     },
  //     data: {
  //       sessionId: sessionId,
  //     },
  //   };

  //   axios(config)
  //     .then(async (response) => {
  //       const data = response.data;
  //       setTitle(data.title);
  //       setDescription(data.description);
  //       setPrice(data.price);
  //       setStock(data.stock);
  //       setWeight(data.weight);
  //       setImageUrls(data.images);
  //       setTags(
  //         data.tags.map((tag) => {
  //           let tagsString = "";
  //           tagsString += tag;
  //           return tagsString;
  //         })
  //       );
  //       setEventId(data.event.id);
  //       setEventName(data.event.name);
  //       setEventIndex(-1);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       toast("Failed to fetch session info", darkToast);
  //     });
  // };

  // const uploadImages = (image) => {
  //   setUploadingImages(true);
  //   const fileName = `${
  //     image.name + "_" + title + "_" + Date.now().toString()
  //   }`;

  //   const upload = storage.ref(`images/${fileName}`).put(image);
  //   upload.on(
  //     "state_changed",
  //     (snapshot) => () => {},
  //     (error) => {},
  //     () => {
  //       storage
  //         .ref("images")
  //         .child(fileName)
  //         .getDownloadURL()
  //         .then((url) => {
  //           setImageUrls((prevState) => [...prevState, url]);
  //           setUploadingImages(false);
  //         });
  //     }
  //   );
  //   return 0;
  // };

  // const updateSession = () => {
  //   if (imageUrls.length === 0) {
  //     return toast("Upload atleast 1 image", darkToast);
  //   }

  //   setLoading(true);
  //   const config = {
  //     method: "post",
  //     url: `${serverUrl}/sessions/update`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `JWT ${localStorage.getItem("token")}`,
  //     },
  //     data: {
  //       sessionId: sessionId,
  //       query: {
  //         title: title,
  //         description: description,
  //         price: parseInt(price),
  //         stock: parseInt(stock),
  //         weight: weight,
  //         images: imageUrls,
  //         event: {
  //           id: eventIndex === -1 ? eventId : events[eventIndex]._id,
  //           name: eventIndex === -1 ? eventName : events[eventIndex].name,
  //         },
  //         tags: tags.toString().split(","),
  //       },
  //     },
  //   };

  //   axios(config)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         toast("Session updated!", darkToast);

  //         setTimeout(() => {
  //           history.push("/sessions");
  //           window.location.reload();
  //         }, 1000);
  //         setLoading(false);
  //       }
  //     })
  //     .catch(() => {
  //       toast("Something went wrong!", darkToast);
  //       setLoading(false);
  //     });
  // };

  return (
    <div className="content-body">
      <div className="content-title">
        <p>Edit Session</p>
      </div>
      <div className="form">
        <p>Event</p>
        <select
          defaultValue={null}
          value={eventIndex}
          onChange={(e) => {
            setEventIndex(e.target.value);
          }}
        >
          <option value="null">Select your Event</option>
          {event.map((event, index) => {
            return <option value={index}>{event.EventName}</option>;
          })}
        </select>

        <p>Session Name</p>
        <input
          type="text"
          maxLength={25}
          placeholder="Session Name"
          value={SessionName}
          onChange={(e) => {
            setSessionName(e.target.value);
          }}
        />
        <p>Description</p>
        <input
          type="text"
          maxLength={60}
          value={description}
          placeholder="Description"
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />

        <p>RewardProfile</p>
        <select
          defaultValue={null}
          value={rewardProfileIndex}
          onChange={(e) => {
            setRewardProfileIndex(e.target.value);
          }}
        >
          <option value="null">Select your Profile</option>
          {rewardProfiles.map((rewardProfiles, index) => {
            return <option value={index}>{rewardProfiles.ProfileName}</option>;
          })}
        </select>

        <p>Qr Count</p>
        <input
          type="rewardcount"
          value={RewardCount}
          placeholder="Qr Count"
          onChange={(e) => {
            setRewardCount(e.target.value);
          }}
        />

        <p>Span Time</p>
        <input
          type="number"
          value={SpanTime}
          placeholder="SpanTime"
          onChange={(e) => {
            setSpanTime(e.target.value);
          }}
        />

        <p>Start Date</p>
        <input
          type="date"
          placeholder="dd-mm-yyyy"
          min="1990-01-01"
          max="2099-12-31"
          value={StartDate}
          onChange={(e) => {
            const date = e.target.value;
            let stDate = new Date(
              `${date.split("-")[0]}-${date.split("-")[1]}-${
                date.split("-")[2]
              }T00:00:00Z`
            ).toISOString();
            setStartDate(stDate);
          }}
        />
        <p>End Date</p>
        <input
          type="date"
          placeholder="dd-mm-yyyy"
          min="2021-01-01"
          max="2099-12-31"
          onChange={(e) => {
            const date = e.target.value;
            let enDate = new Date(
              `${date.split("-")[0]}-${date.split("-")[1]}-${
                date.split("-")[2]
              }T00:00:00Z`
            ).toISOString();
            setEndDate(enDate);
          }}
        />

        <p>Start Time</p>
        <input
          type="time"
          // placeholder="dd-mm-yyyy"
          // min="2021-01-01"
          // max="2099-12-31"
          onChange={(e) => {
            var timeSplit = e.target.value.split(":"),
              hours,
              minutes,
              meridian;
            hours = timeSplit[0];
            minutes = timeSplit[1];
            if (hours > 12) {
              meridian = "PM";
              hours -= 12;
            } else if (hours < 12) {
              meridian = "AM";
              if (hours === 0) {
                hours = 12;
              }
            } else {
              meridian = "PM";
            }
            setStartTime(hours + ":" + minutes + " " + meridian);
          }}
          // onChange={(e) => {
          //   const date = e.target.value;
          //   console.log(date);
          // let strTime = new Date(
          //   `${date.split("-")[0]}-${date.split("-")[1]}-${
          //     date.split("-")[2]
          //   }T00:00:00Z`
          // ).toISOString();
          // const n = strTime.toLocaleTimeString();
          // setStartTime(n);
          // }}
        />

        <p>End Time</p>
        <input
          type="time"
          placeholder="dd-mm-yyyy"
          min="2021-01-01"
          max="2099-12-31"
          onChange={(e) => {
            var timeSplit = e.target.value.split(":"),
              hours,
              minutes,
              meridian;
            hours = timeSplit[0];
            minutes = timeSplit[1];
            if (hours > 12) {
              meridian = "PM";
              hours -= 12;
            } else if (hours < 12) {
              meridian = "AM";
              if (hours === 0) {
                hours = 12;
              }
            } else {
              meridian = "PM";
            }
            setEndTime(hours + ":" + minutes + " " + meridian);
          }}
          // onChange={(e) => {
          //   const date = e.target.value;
          //   let enTime = new Date(
          //     `${date.split("-")[0]}-${date.split("-")[1]}-${
          //       date.split("-")[2]
          //     }T00:00:00Z`
          //   ).toISOString();
          //   const n = enTime.toLocaleTimeString();
          //   setEndTime(n);
          // }}
        />

        <button
          onClick={() => {
            updateSessions();
          }}
        >
          {loading ? (
            <ReactLoading
              type={"spinningBubbles"}
              color={"white"}
              width={"1.2rem"}
              height={"1.2rem"}
            />
          ) : (
            "Save"
          )}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditSession;
