import React, { useState, useEffect } from "react";
import { Route, useHistory } from "react-router";
import AddSession from "./AddSession";
import ReactLoading from "react-loading";
import { serverUrl } from "../../Utils/server";
import axios from "axios";
import FontAwesome from "react-fontawesome";
import { toast, ToastContainer, darkToast } from "../../Components/Toast";
import EditSession from "./EditSession";
import Modal from "react-modal/lib/components/Modal";
import Qrpage from "./Qrpage";
import ViewReports from "./ViewReports";
import QR_Reports from "./QR_Reports";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
const perPage = 10;

function Sessions() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(false);

  const [qr, setQr] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [searchSessions, setSearchSessions] = useState("");
  const [deleteSession, setDeleteSession] = useState("");
  const [reportSession, setreportSession] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [sessionActive, setSessionActive] = useState(0);

  const updateSessionById = (_id, data) => {
    console.log(_id, data);
    let tempSessions = sessions;
    const index = tempSessions.findIndex((item) => item._id === _id);
    console.log(index);
    tempSessions[index] = data;
    console.log(sessions);
    setSessions(tempSessions);
    setReload(!reload);
    window.reload();
    console.log(tempSessions);
    // setSessions((session) => [...session, data]);
  };

  const updateStatus = (status, id) => {
    console.log(status, id);

    //setLoading(true);
    const config = {
      method: "post",
      url: `${serverUrl}/sessions/SessionActiveOrNot`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        sessionId: id,
        Status: status,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response);
        getSessions();
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    getSessions();
  }, []);

  const generateQrCode = (session_id) => {
    const config = {
      method: "post",
      url: `${serverUrl}/sessions/qr-generate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        sessionId: session_id,
      },
    };

    axios(config)
      .then((response) => {
        console.log("resp", response);
        if (response.status === 200) {
          updateSessionById(response?.data?.data?._id, response?.data?.data);
        }
      })
      .catch((err) => {
        toast("Something went wrong", darkToast);
        setReload(!reload);
      });
  };

  const updateSessionStatusBy_id = (_id, status) => {
    let index = sessions.findIndex((item) => item._id == _id);
    console.log(index);
    let allSession = sessions;
    allSession[index]["IsActive"] = status;
    console.log(allSession);
    setQr(allSession);
  };

  // const getActive = (sessionActive, status) => {
  //   console.log(sessionActive);

  //   setLoading(true);
  //   const config = {
  //     method: "post",
  //     url: `${serverUrl}/sessions/SessionActiveOrNot`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `JWT ${localStorage.getItem("token")}`,
  //     },
  //     data: {
  //       sessionId: sessionActive._id,
  //       status: status,
  //     },
  //   };

  //   axios(config)
  //     .then((response) => {
  //       console.log(response);
  //       setSessionActive(response);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  // };

  const getSessions = () => {
    setLoading(true);

    const config = {
      method: "get",
      url: `${serverUrl}/sessions/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };

    axios(config).then((response) => {
      setSessions(response.data);
      console.log(response.data);
      setSessions(response.data);
      localStorage.setItem("sessions", JSON.stringify(response.data));
      setLoading(false);
    });
  };

  const removeSession = () => {
    setLoading(true);
    const config = {
      method: "post",
      url: `${serverUrl}/sessions/remove`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        sessionId: deleteSession._id,
      },
    };

    axios(config)
      .then((response) => {
        toast("Session deleted successfully!", darkToast);
      })
      .catch((err) => {
        toast("Something went wrong", darkToast);
      });

    setDeleteSession("");
    getSessions();
    setLoading(false);
    window.location.reload()
  };


// tea  
  // function downloadBase64File(base64Data, fileName) {
  //   const linkSource = base64Data;
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = linkSource;
  //   downloadLink.download = fileName;
  //   downloadLink.click();
  // }

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  const offset = currentPage * perPage;
  const currentPageData = sessions
    .slice(offset, offset + perPage)
    .map((session, index) => {
      return !session.SessionName?.toLowerCase().includes(
        searchSessions.toLowerCase()
      ) ? (
        ""
      ) : (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{session.SessionName}</td>
          <td>
            {session.event.map((event) => (
              <p>{event.EventName}</p>
            ))}
          </td>
          <td>{session.description}</td>
          <td>
            {session.rewardprofile.map((rewardprofile) => (
              <p>{rewardprofile.rewardprofileName}</p>
            ))}
          </td>
          <td>{session.RewardCount}</td>
          <td>{session.SpanTime}</td>

          <td>{session.StartDate.slice(0, 10)}</td>
          <td>{session.EndDate.slice(0, 10)}</td>
          <td>{session.StartTime}</td>
          <td>{session.EndTime}</td>
          <td>
            <button
              onClick={() => {
                history.push(`/sessions/edit/${sessions[index]._id}`);
              }}
              style={{
                background: "white",
                border: "1px solid var(--primaryColor)",
              }}
            >
              <FontAwesome
                name="pencil"
                style={{
                  fontSize: "10px",
                  color: "var(--primaryColor)",
                }}
              />
            </button>
          </td>
          <td>
            <button
              className="viewbutton"
              onClick={() => setDeleteSession(session)}
            >
              <FontAwesome
                name="trash"
                style={{ fontSize: "12px", color: "white" }}
              />
            </button>
          </td>
          <td>
            {session?.QrImage.length > 0 ? (
              <button
                onClick={() => {
                  history.push(`/sessions/Qrpage/${sessions[index]._id}`);
                  setQr(session?.QrImage);
                }}
                style={{
                  backgroundColor: "#1fb161",
                  fontSize: "9px",
                  height: "20px",
                }}
              >
                ShowQR
              </button>
            ) : (
              <button
                onClick={() => {
                  generateQrCode(session._id);
                }}
              >
                Genrate
              </button>
            )}
          </td>
          <td>
            <button
              className="viewbutton"
              onClick={() => {
                history.push(`/getReports/:sessionId/${sessions[index]._id}`);
                setreportSession(session);
              }}
            >
              View
              {/* <FontAwesome
                name="trash"
                // style={{ fontSize: "14px", color: "white" }} 
               /> */}
            </button>
          </td>
          <td>
            <label class="switch">
              <input type="checkbox" onClick={() => setSessionActive(false)} />
              <span class="slider round"></span>
            </label>
          </td>
        </tr>
      );
    });
  const pageCount = Math.ceil(sessions.length / perPage);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function CustomToolbarGrid() {
    const { data } = useDemoData({
      dataSet: "Employee",
      rowLength: 10,
      maxColumns: 6,
    });

    const columns = [
      { field: "sno", headerName: "S NO", width: 50 },
      {
        headerName: "Session Name",
        field: "SessionName",
        width: 130,
      },
      {
        headerName: "Event Name",
        field: "EventName",
        width: 110,
      },
      {
        headerName: "Description",
        field: "description",
        width: 110,
      },
      {
        headerName: "Reward Profile",
        field: "rewardprofileName",
        width: 130,
      },
      {
        headerName: "Qr Count",
        field: "RewardCount",
        width: 100,
      },
      {
        headerName: "Span Time",
        field: "SpanTime",
        width: 100,
      },
      {
        headerName: "Start date",
        field: "StartDate",
        width: 120,
      },
      {
        headerName: "End date",
        field: "EndDate",
        width: 120,
      },
      {
        headerName: "Start Time",
        field: "StartTime",
        width: 90,
      },

      {
        headerName: "End time",
        field: "EndTime",
        width: 90,
        editable: true,
      },
      {
        headerName: "Edit",
        field: "edit",
        width: 70,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking
            history.push(`/sessions/edit/${params.id}`);

            console.log(params.id);
          };
          return (
            <>
              <button onClick={onClick}>Edit</button>
            </>
          );
        },
      },
      {
        headerName: "Delete",
        field: "delete",
        width: 90,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking
            let session = sessions.filter((item) => item.id === params.id);
            console.log(session[0]);
            setDeleteSession(session[0]);

            console.log(params.id);
          };
          return (
            <>
              <button onClick={onClick}>Delete</button>
            </>
          );
        },
      },
      {
        headerName: "QR",
        field: "qrcode",
        width: 90,
        renderCell: (params) => {
          let session = sessions.filter((item) => item.id === params.id);
          const onClick = (e) => {
            e.stopPropagation();

            if (session[0]?.QrImage.length > 0) {
              history.push(`/sessions/Qrpage/${params.id}`);
              setQr(session[0]?.QrImage);
            } else {
              generateQrCode(params.id);
            }

            console.log(params.id);
          };
          return (
            <>
              <button onClick={onClick}>
                {session[0]?.QrImage.length > 0 ? "View" : "Generate"}
              </button>
            </>
          );
        },
      },
      // {
      //   headerName: "Report",
      //   field: "report",
      //   width: 70,
      //   renderCell: (params) => {
      //     const onClick = (e) => {
      //       e.stopPropagation(); // don't select this row after clicking
      //       let session = sessions.filter((item) => item.id === params.id);
      //       history.push(`/sessions/QR_Reports/${params.id}`);
      //       setreportSession(session[0]);
      //     };
      //     return (
      //       <>
      //         <button onClick={onClick}>View</button>
      //       </>
      //     );
      //   },
      // },
      {
        headerName: "ActiveInactive",
        field: "Status",
        width: 120,
        renderCell: (params) => {
          console.log("Status", params);

          return (
            <>
              <label class="switch">
                <input
                  type="checkbox"
                  onChange={
                    (e) => updateStatus(e.target.checked, params.id)
                    //setSessionActive(sessions, !sessions.IsActive)
                  }
                  checked={params.value}
                />
                {params.Status}
                <span class="slider round"></span>
              </label>
            </>
          );
        },
      },
    ];

    let rows = sessions;
    rows.forEach((element, i) => {
      rows[i]["id"] = rows[i]["_id"];
      rows[i]["sno"] = i + 1;
      rows[i]["EventName"] = rows[i]["event"][0]?.EventName;
      rows[i]["rewardprofileName"] =
        rows[i]["rewardprofile"][0]?.rewardprofileName;
      rows[i]["edit"] = i;
      rows[i]["reports"] = i;
    });

    console.log(data);

    return (
      <div style={{ height: 560, width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          // {...data}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  return (
    <>
      <div className="grid-parent">
        {/* <div className="grid-1">
          <CustomToolbarGrid />
        </div> */}
        {/* <div className="grid-2">
          {sessions.map((session, index) => (
            <div>
              <button
                onClick={() => {
                  history.push(`/sessions/edit/${sessions[index]._id}`);
                }}
                style={{
                  background: "white",
                  border: "1px solid var(--primaryColor)",
                }}
              >
                <FontAwesome
                  name="pencil"
                  style={{
                    fontSize: "10px",
                    color: "var(--primaryColor)",
                  }}
                />
              </button>
              <button>Delete</button>
              {session?.QrImage.length > 0 ? (
                <button
                  onClick={() => {
                    history.push(`/sessions/Qrpage/${sessions[index]._id}`);
                    setQr(session?.QrImage);
                  }}
                  style={{
                    backgroundColor: "#1fb161",
                    fontSize: "9px",
                    height: "20px",
                  }}
                >
                  ShowQR
                </button>
              ) : (
                <button
                  onClick={() => {
                    generateQrCode(session._id);
                  }}
                >
                  Genrate
                </button>
              )}
              <button>show</button>
            </div>
          ))}
        </div> */}
      </div>
      <Route exact path="/sessions">
        <Modal
          isOpen={deleteSession !== ""}
          style={{
            content: {
              borderRadius: "1rem",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
            overlay: {
              backgroundColor: "rgba(0,0,0,0.7)",
            },
          }}
        >
          <div className="modalContent">
            <p className="modalTitle">Delete Session?</p>
            <p className="modalBody">
              Are you sure, want to delete{" "}
              <b>{`${deleteSession.SessionName}`}</b> ?
            </p>
            {loading ? (
              <>
                <br />
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"var(--primaryColor)"}
                  width={"2rem"}
                  height={"2rem"}
                />
              </>
            ) : (
              <div className="modalButtonsRow">
                <button
                  className="cancelbutton"
                  onClick={() => setDeleteSession("")}
                >
                  Cancel
                </button>
                <button
                  className="deletebutton"
                  onClick={() => {
                    setLoading(true);
                    removeSession();
                    // toast("Can't delete Session in Demo", darkToast);
                  }}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </Modal>

        {/* <Modal
          isOpen={reportSession !== ""}
          style={{
            content: {
              borderRadius: "1rem",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
            overlay: {
              backgroundColor: "rgba(0,0,0,0.7)",
            },
          }}
        >
          <div className="modalContent">
            <p className="modalTitle">Report Session?</p>
            <p className="modalBody">
              Are you sure, want to open Report{" "}
              <b>{`${reportSession.SessionName}`}</b> ?
            </p>
            {loading ? (
              <>
                <br />
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"var(--primaryColor)"}
                  width={"2rem"}
                  height={"2rem"}
                />
              </>
            ) : (
              <div className="modalButtonsRow">
                <button
                  className="cancelbutton"
                  onClick={() => setreportSession("")}
                >
                  Close
                </button>
                <button className="deletebutton"
                  onClick={() => {
                    setLoading(true);
                    removeSession();
                    // toast("Can't delete Session in Demo", darkToast);
                  }}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </Modal> */}

        {/* {show && (
          <>
            {qr.map((image, index) => (
              <div className="qr-code" key={index}>
                <img src={image.QrURL} alt="qr" />
                <button
                  className="downloadQr"
                  variant="primary"
                  onClick={() => {
                    downloadBase64File(image.QrURL, "qr code");
                  }}
                >
                  Download QR
                </button>
                <button
                  className="downloadQr"
                  variant="secondary"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            ))}
          </>
        )} */}

        <div className="content-body">
          <div className="content-title">
            <p>Sessions</p>
            <button
              className="adduserevent"
              onClick={() => {
                history.push("/sessions/new");
              }}
            >
              + Add New Session
            </button>
          </div>

          <div
            style={{
              height: loading ? "70vh" : "",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <ReactLoading
                type={"spinningBubbles"}
                color={"var(--primaryColor)"}
                width={"4rem"}
                height={"4rem"}
              />
            ) : sessions.length === 0 ? (
              <p>No Sessions</p>
            ) : (
              <CustomToolbarGrid />
            )}
          </div>
          <ToastContainer />
        </div>
      </Route>
      <Route path="/sessions/new" component={AddSession} />
      <Route path="/sessions/edit/:sessionId" component={EditSession} />
      <Route path="/sessions/Qrpage/:sessionId" component={Qrpage} />
      <Route path="/sessions/Qr_Reports/:sessionId" component={QR_Reports} />
      <Route
        path="/sessions/ViewReports/:QrId/:RewardId"
        component={ViewReports}
      />
    </>
  );
}

export default Sessions;
