import React, { useState } from "react";
import { logo, serverUrl } from "../../Utils/server"; // Assuming you have logo import
import "./DeleteCustomer.css"; // Import the CSS file for styling
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import { toast, ToastContainer, lightToast } from "../../Components/Toast";
import { useHistory } from "react-router-dom";
import axios from "axios";

const DeleteUser = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // Handle input changes for email and phone number
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneChange = (e) => setPhone(e.target.value);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email && phone) {
      setLoading(true); // Start loading
      const data = { email, phone };
      try {
        const response = await axios.post(
          `${serverUrl}/customer/inactive-account`, // API endpoint
          data // Request payload
        );

        if (response.status === 200) {
          toast("Your account has been deleted successfully.", lightToast);
          setTimeout(() => {
            history.push("/login"); // Navigate to login page after success
          }, 2000); // Wait for 2 seconds before redirect
        } else {
          toast("Something went wrong, please try again.", lightToast);
        }
      } catch (error) {
        console.error("Error deleting account:", error);
        toast(error.message, lightToast);
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      toast(
        "Please provide both valid email and phone number to confirm account deletion.",
        lightToast
      );
    }
  };

  return (
    <div className="delete-user-container">
      <div className="form-container">
        <div className="logo-container">
          <img src={logo} alt="Logo" />
          <p>Cgrum App</p>
        </div>
        <p className="title">Delete Your Account</p>
        <p>Enter your email and phone number to confirm account deletion.</p>
        <form onSubmit={handleSubmit} className="delete-user-form">
          <div className="input-containers">
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
            />

            <input
              type="number"
              id="phone-number"
              value={phone}
              onChange={handlePhoneChange}
              placeholder="Enter your phone number"
            />
          </div>
          <button type="submit" className="delete-btn" disabled={loading}>
            {loading ? (
              <ReactLoading type="spin" color="#fff" height={24} width={24} />
            ) : (
              "Confirm Deletion"
            )}
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DeleteUser;
