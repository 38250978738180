import React, { useState, useEffect } from "react";
import { darkToast, toast, ToastContainer } from "../../Components/Toast";
import ReactLoading from "react-loading";
import { useHistory } from "react-router";
import axios from "axios";
import { serverUrl } from "../../Utils/server";
import "./rewardProfile.css";

export const AddRewardProfile = () => {
  const history = useHistory();
  const [profileName, setProfileName] = useState("");
  const [reward, setReward] = useState([]);
  const [rewardIndex, setRewardIndex] = useState(-1);
  const [order, setOrder] = useState("");
  const [quantity, setQuantity] = useState("");
  const [checked, setChecked] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectRewardData, setSelectRewardData] = useState([]);
  const [ScanCount, setScanCount] = useState("");

  useEffect(() => {
    getReward();
  }, []);

  const checkedbox = (e, data) => {
    if (e.target.checked) {
      console.log(data);
      setSelectRewardData([...selectRewardData, data]);
    } else {
      let filterData = selectRewardData.filter((obj) => {
        return obj._id !== data._id;
      });
      setSelectRewardData(filterData);
    }
  };

  let existingrewardprofile = [];
  let filteredrewardprofile = [];
  const getRewardProfiles = () => {
    // setLoading(true);

    const config = {
      method: "get",
      url: `${serverUrl}/rewardprofiles/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then((response) => {
        existingrewardprofile = response.data;
        // setRewardProfiles(response.data);
        console.log(existingrewardprofile);
        filteredrewardprofile = existingrewardprofile.map(({ ProfileName }) => ProfileName.toLowerCase());

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  getRewardProfiles()

  const addRewardProfile = () => {
    if (profileName === "") {
      return toast("ProfileName is required", darkToast);
    }else if (filteredrewardprofile.includes(profileName.toLowerCase().trim())) {
      return toast("The Reward Profile already exists", darkToast);
    }

    let rewardData = [];
    selectRewardData.forEach((data) => {
      let obj = {
        RewardId: data._id,
        RewardName: data.RewardType,
        Quantity: document.getElementById(`${data.RewardType}_quantitybox`)
          .value,
        Order: document.getElementById(`${data.RewardType}_orderbox`).value,
        ScanCount: document.getElementById(`${data.RewardType}_quantitybox`).value,
      };
      rewardData.push(obj);
    });
    console.log("rewardData", rewardData);

    setLoading(true);

    const config = {
      method: "post",
      url: `${serverUrl}/rewardProfiles/add`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        ProfileName: profileName,
        Rewards: rewardData.sort((a, b) => a.Order - b.Order)
      },
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          toast("RewardProfile added!", darkToast);
          setTimeout(() => {
            history.push("/RewardProfile");
            window.location.reload();
          }, 1000);
          setLoading(false);
        }
      })
      .catch(() => {
        toast("Something went wrong!", darkToast);
        setLoading(false);
      });
  };

  const getReward = () => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${serverUrl}/rewards/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then((response) => {
        setReward(response.data);
        setLoading(false);
        setRewardIndex(0);
      })
      .catch((err) => {
        toast("Couldn't load reward type. Please refresh.", darkToast);
        setLoading(false);
      });
  };

  return (
    <div className="content-body">
      <div className="content-title">
        <p>Add New Reward Profile</p>
      </div>
      <div className="form">
        <p>profile Name</p>
        <input
          type="text"
          value={profileName}
          id={profileName}
          placeholder="profile name"
          onChange={(e) => {
            setProfileName(e.target.value);
          }}
        />
        <p>Reward Types</p>
        {/* <input
          type="text"
          value={quantity}
          placeholder="quantity"
          onChange={(e) => {
            setQuantity(e.target.value);
          }}
        /> */}
        <div className="reward-type-parent">
          {reward.map((reward, index) => (
            <div className="reward-type-box" key={index}>
              <label className="rewardrow">
                <div>
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(e) => {
                      checkedbox(e, reward);
                    }}
                  />
                </div>
                <div className="rewardtype">{reward.RewardType}</div>
              </label>
              {selectRewardData?.includes(reward) && (
                <>
                  <div>
                    <input
                      className="quantitybox"
                      type="number"
                      id={`${reward.RewardType}_quantitybox`}
                      placeholder="qty"
                      onChange={(e) => {
                        console.log(e.target.value);
                      }}
                    />
                  </div>
                  <input
                    className="orderbox"
                    type="number"
                    id={`${reward.RewardType}_orderbox`}
                    placeholder="order"
                    onChange={(e) => {
                      console.log(e.target.value);
                    }}
                  />
                </>
              )}
            </div>
          ))}
        </div>

        <button
          className="addrewardprofile"
          onClick={() => {
            // getRewardProfiles()
            addRewardProfile();
            // toast("Can't add Coupon in Demo", darkToast);
          }}
        >
          {loading ? (
            <ReactLoading
              type={"spinningBubbles"}
              color={"white"}
              width={"1.2rem"}
              height={"1.2rem"}
            />
          ) : (
            "Add Reward Profile"
          )}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};
