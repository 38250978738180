import "./App.css";
import { Switch, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import SignUp from "./Pages/Signup/SignUp";
import Header from "./Components/Header/Header";
import DeleteCustomer from "./Pages/InActiveCustomer/DeleteCustomer";
import ActivateCustomer from "./Pages/ActivateCustomer/ActivateCustomer";

function App() {
  return (
    <>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/signup" component={SignUp} />
        <Route path="/delete-customer" component={DeleteCustomer} />
        <Route path="/active-customer" component={ActivateCustomer} />
        <Route path="/" component={Home} />
        <Route path="/header" component={Header} />
      </Switch>
    </>
  );
}

export default App;
