import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Route } from "react-router-dom";
import { ToastContainer, toast, darkToast } from "../../Components/Toast";
import ReactLoading from "react-loading";
import { AddRewardProfile } from "./AddRewardProfile";
import { serverUrl } from "../../Utils/server";
import axios from "axios";
import FontAwesome from "react-fontawesome";
import Modal from "react-modal";
import { EditRewardProfile } from "./EditRewardProfile";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
const perPage = 5;

function RewardProfile() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [rewardProfiles, setRewardProfiles] = useState([]);
  const [searchProfileName, setSearchProfileName] = useState("");
  const [deleteRewardProfile, setDeleteRewardProfile] = useState("");
  const [showMore, setshowMore] = useState(false);
  const [selectedReward, setselectedReward] = useState({});
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getRewardProfiles();
  }, []);

  const getRewardProfiles = () => {
    setLoading(true);

    const config = {
      method: "get",
      url: `${serverUrl}/rewardprofiles/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then((response) => {
        setRewardProfiles(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getRewardProfilesByType = (type) => {
    if (type === "all") return getRewardProfiles();
    setLoading(true);

    const config = {
      method: "post",
      url: `${serverUrl}/rewardprofiles/get-rewardprofiles-by-type`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        type: type,
      },
    };

    axios(config)
      .then((response) => {
        setRewardProfiles(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const removeRewardProfile = () => {
    setLoading(true);
    const config = {
      method: "post",
      url: `${serverUrl}/rewardprofiles/remove`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        rewardprofileId: deleteRewardProfile._id,
      },
    };

    axios(config)
      .then((response) => {
        toast("Reward profile deleted successfully!", darkToast);
        setLoading(false);
      })
      .catch((err) => {
        toast("Something went wrong", darkToast);
        setLoading(false);
      });

    setDeleteRewardProfile("");
    getRewardProfiles();
  };

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  const offset = currentPage * perPage;
  const currentPageData = rewardProfiles
    .slice(offset, offset + perPage)
    .map((rewardProfile, index) => {
      return !rewardProfile.ProfileName.toLowerCase().includes(
        searchProfileName.toLowerCase()
      ) ? (
        ""
      ) : (
        <tr key={index}>
          <td>{index + 1}</td>
          <td
            className="rewardprofile"
            onClick={() => {
              setselectedReward(rewardProfile);
              setshowMore(true);
            }}
          >
            {rewardProfile.ProfileName}
          </td>

          <td>{rewardProfile.createdOn.toString().split("T")[0]}</td>
          <td>
            <button
              onClick={() => {
                history.push(`/RewardProfile/edit/${rewardProfile._id}`);
              }}
              style={{
                background: "white",
                border: "1px solid var(--primaryColor)",
              }}
            >
              <FontAwesome
                name="pencil"
                style={{
                  fontSize: "1rem",
                  color: "var(--primaryColor)",
                }}
              />
            </button>
          </td>
          <td>
            <button
              className="rewarddeletebutton"
              onClick={() => setDeleteRewardProfile(rewardProfile)}
            >
              <FontAwesome
                name="trash"
                style={{ fontSize: "1rem", color: "white" }}
              />
            </button>
          </td>
        </tr>
      );
    });

  const pageCount = Math.ceil(rewardProfiles.length / perPage);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function CustomToolbarGrid() {
    const { data } = useDemoData({
      dataSet: "Employee",
      rowLength: 10,
      maxColumns: 6,
    });

    const columns = [
      { field: "sno", headerName: "S No", width: 60 },
      {
        headerName: "Profile Name",
        field: "ProfileName",
        width: 200,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking
            setshowMore(true);
            setselectedReward(params.row);
            console.log(params);
          };
          return (
            <>
              <span className="rewardprofilename" onClick={onClick}>{params.value}</span>
            </>
          );
        },
      },
      {
        headerName: "Edit",
        field: "edit",
        width: 130,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking
            history.push(`/RewardProfile/edit/${params.id}`);

            console.log(params.id);
          };
          return (
            <>
              <button onClick={onClick}>Edit</button>
            </>
          );
        },
      },
      {
        headerName: "Delete",
        field: "delete",
        width: 100,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking
            let profile = rewardProfiles.filter(
              (item) => item.id === params.id
            );
            console.log(profile[0]);
            setDeleteRewardProfile(profile[0]);

            console.log(params.id);
          };
          return (
            <>
              <button onClick={onClick}>Delete</button>
            </>
          );
        },
      },
    ];
    let rows = rewardProfiles;
    rows.forEach((element, i) => {
      rows[i]["id"] = rows[i]["_id"];
      rows[i]["sno"] = i + 1;
      // rows[i]["rewardprofileName"] =
      // rows[i]["rewardprofile"][0]?.rewardprofileName;
      rows[i]["edit"] = i;
    });

    return (
      <div style={{ height: 550, width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          // {...data}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  return (
    <>
      <div className="grid-parent"></div>

      <Route exact path="/RewardProfile">
        <Modal
          isOpen={deleteRewardProfile !== "" || showMore}
          style={{
            content: {
              borderRadius: "1rem",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
            overlay: {
              backgroundColor: "rgba(0,0,0,0.7)",
            },
          }}
        >
          {showMore ? (
            <div>
              <div
                style={{
                  height: loading ? "70vh" : "",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <table>
                  <tr>
                    <th>Reward Name</th>
                    <th>Quantitiy</th>
                    <th>Order</th>
                  </tr>
                  {selectedReward?.Rewards?.map((reward, index) => (
                    <tr key={index}>
                      <td>{reward?.RewardName}</td>
                      <td>{reward?.Quantity}</td>
                      <td>{reward?.Order}</td>
                    </tr>
                  ))}
                </table>
              </div>
              <div className="modalButtonsRow">
                <button
                  className="cancelbutton"
                  onClick={() => setshowMore(false)}
                >
                  Close
                </button>
              </div>
            </div>
          ) : (
            <div className="modalContent">
              <p className="modalTitle">Delete RewardPofile?</p>
              <p className="modalBody">
                Are you sure, want to delete{" "}
                <b>{`${deleteRewardProfile.ProfileName}`}</b> ?
              </p>
              {loading ? (
                <>
                  <br />
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"var(--primaryColor)"}
                    width={"2rem"}
                    height={"2rem"}
                  />
                </>
              ) : (
                <div className="modalButtonsRow">
                  <button
                    className="cancelbutton"
                    onClick={() => setDeleteRewardProfile("")}
                  >
                    Cancel
                  </button>
                  <button
                    className="deletebutton"
                    onClick={() => {
                      setLoading(true);
                      removeRewardProfile();
                      // toast("Can't delete events in Demo", darkToast);
                    }}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          )}
        </Modal>
        <div className="content-body">
          <div className="content-title">
            <p>Reward Profile</p>
            <button
              className="addRewardProfile"
              onClick={() => {
                history.push("/RewardProfile/new");
              }}
            >
              + Add New Reward Profile
            </button>
          </div>
          {/* <div className="filter-select">
            <p>RewardProfile Type:</p>
            <select onChange={(e) => getRewardProfilesByType(e.target.value)}>
              <option value="all">All</option>
              <option value="profileName">Profile Name</option>
            </select>
            <input
              type="text"
              placeholder="Search profile name"
              onChange={(e) => setSearchProfileName(e.target.value)}
            />
          </div> */}
          <div
            style={{
              height: loading ? "70vh" : "",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {
              loading ? (
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"var(--primaryColor)"}
                  width={"4rem"}
                  height={"4rem"}
                />
              ) : rewardProfiles.length === 0 ? (
                <p>No Reward</p>
              ) : (
                <CustomToolbarGrid />
              )
              // (
              //   <table>
              //     <tr>
              //       <th>No.</th>
              //       <th>Reward Profile</th>
              //       <th>Created On</th>
              //       <th>Edit</th>
              //       <th>Delete</th>
              //     </tr>
              //     {/* {currentPageData} */}
              //     {/* {rewardProfiles.map((rewardProfile, index) => {
              //       return !rewardProfile.ProfileName.toLowerCase().includes(
              //         searchProfileName.toLowerCase()
              //       ) ? (
              //         ""
              //       ) : (
              //         <tr key={index}>
              //           <td>{index + 1}</td>
              //           <td
              //             className="rewardprofile"
              //             onClick={() => {
              //               setselectedReward(rewardProfile);
              //               setshowMore(true);
              //             }}
              //           >
              //             {rewardProfile.ProfileName}
              //           </td>

              //           <td>
              //             {rewardProfile.createdOn.toString().split("T")[0]}
              //           </td>
              //           <td>
              //             <button
              //               onClick={() => {
              //                 history.push(
              //                   `/RewardProfile/edit/${rewardProfile._id}`
              //                 );
              //               }}
              //               style={{
              //                 background: "white",
              //                 border: "1px solid var(--primaryColor)",
              //               }}
              //             >
              //               <FontAwesome
              //                 name="pencil"
              //                 style={{
              //                   fontSize: "1rem",
              //                   color: "var(--primaryColor)",
              //                 }}
              //               />
              //             </button>
              //           </td>
              //           <td>
              //             <button
              //               className="rewarddeletebutton"
              //               onClick={() => setDeleteRewardProfile(rewardProfile)}
              //             >
              //               <FontAwesome
              //                 name="trash"
              //                 style={{ fontSize: "1rem", color: "white" }}
              //               />
              //             </button>
              //           </td>
              //         </tr>
              //       );
              //     })} */}
              //   </table>
              // )
            }
          </div>
          {/* <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            breakLabel={"..."}
            marginPagesDisplayed={3}
            pageRangeDisplayed={6}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakLinkClassName={"page-link"}
            breakClassName={"page-item"}
            activeClassName={"active"}
          /> */}
          <ToastContainer />
        </div>
      </Route>
      <Route path="/RewardProfile/new" component={AddRewardProfile} />
      <Route
        path="/RewardProfile/edit/:rewardprofileId"
        component={EditRewardProfile}
      />
    </>
  );
}

export default RewardProfile;
