import Events from "./Events";
import Chats from "./Chats";
import RewardProfile from "./RewardProfile";
import Dashboard from "./Dashboard";
import Orders from "./Orders";
import Sessions from "./Sessions";
import Users from "./Users";
import "./Home.css";
import "./HomeContents.css";
import { Route } from "react-router-dom";
import NavButton from "../../Components/NavButton";
import NavBar from "../../Components/NavBar";
import { useHistory } from "react-router-dom";
import SessionRequests from "./SessionRequests";
import Notices from "./Notices";
import MailSettings from "./MailSettings";
import Reward from "./Reward";
import Reports from "./Reports";

function Home() {
  const history = useHistory();
  if (!localStorage.getItem("token")) {
    history.push("/login");
  }

  return (
    <div className="home-container">
      <NavBar />
      <div className="main">
        <div className="menu">
          <ul className="column">
            <div>
              <NavButton
                icon="dashboard"
                title="Dashboard"
                path="/"
              ></NavButton>
              <NavButton icon="cube" title="Events" path="/events"></NavButton>
              <NavButton
                className="rewardsidebutton"
                icon="Example of gift fa-gift"
                title="Rewards"
                path="/reward"
              ></NavButton>
              <NavButton
                icon="ticket"
                title="Reward Profile"
                path="/RewardProfile"
              ></NavButton>
              <NavButton
                icon="list"
                title="Sessions"
                path="/sessions"
              ></NavButton>
              {/* <NavButton
                icon="ticket"
                title="Reward Profile"
                path="/RewardProfile"
              ></NavButton> */}
              {/* <NavButton
                icon="shopping-bag"
                title="Orders"
                path="/orders"
              ></NavButton> */}
              {/* <NavButton
                icon="clipboard"
                title="Session Requests"
                path="/session-requests"
              ></NavButton> */}
              <NavButton icon="user" title="Users" path="/users"></NavButton>
              {/* <NavButton
                icon="comments"
                title="Chats"
                path="/chats"
              ></NavButton> */}

              <NavButton
                icon="Example of database fa-database"
                title="Reports"
                Reports
                path="/reports"
              ></NavButton>
              {/* <NavButton
                icon="bell"
                title="Notices"
                path="/notices"
              ></NavButton> */}
              {/* <NavButton
                icon="envelope"
                title="Mail Settings"
                path="/mail-settings"
              ></NavButton> */}
            </div>
            <NavButton icon="sign-out" title="Logout" path="/login"></NavButton>
          </ul>
        </div>
        <div className="content">
          <Route path="/" exact component={Dashboard} />
          <Route path="/Sessions" component={Sessions} />
          <Route path="/events" component={Events} />
          <Route path="/reward" component={Reward} />
          <Route path="/orders" component={Orders} />
          <Route path="/RewardProfile" component={RewardProfile} />
          <Route path="/session-requests" component={SessionRequests} />
          <Route path="/users" component={Users} />
          <Route path="/reports" component={Reports} />
          <Route path="/chats" component={Chats} />
          <Route path="/notices" component={Notices} />
          <Route path="/mail-settings" component={MailSettings} />
        </div>
      </div>
    </div>
  );
}

export default Home;
