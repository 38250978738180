import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { serverUrl } from "../../Utils/server";
import axios from "axios";
import FontAwesome from "react-fontawesome";
import { toast, ToastContainer, darkToast } from "../../Components/Toast";
import Sessions from "./Sessions";
import { useParams } from "react-router";
import Modal from "react-modal/lib/components/Modal";
import ViewReports from "./QR_Reports";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import { QRCode } from "react-qrcode-logo";
const perPage = 5;

function ViewRewardReports() {
  const { RewardId } = useParams([]);
  const { QrId } = useParams([]);
  console.log(QrId);
  console.log(RewardId);
  const [reports, setReports] = useState("");
  const [searchViewReports, setSearchViewReports] = useState("");
  const [rewardId, setRewardId] = useState("");
  const [qrCode, setQrCode] = useState("");

  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);

  const [deleteViewReports, setDeleteViewReports] = useState("");

  //   useEffect(() => {
  //     // console.log(props);
  //     // getQrImage();
  //     let sessions = localStorage.getItem("sessions") ?? [];
  //     console.log(JSON.parse(sessions));
  //     sessions = JSON.parse(sessions);
  //     let _id = window.location.pathname.replace("/sessions/ViewReports/", "").trim();
  //     sessions.forEach((item) => {
  //       if (item._id == _id) {
  //         setReports(item.QrImage);
  //       }
  //     });
  //   }, []);

  useEffect(() => {
    getReports();
  }, []);

  const getReports = () => {
    setLoading(true);

    const config = {
      method: "post",
      url: `${serverUrl}/reports/RewardsScannedData/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        QRCode: QrId,
        RewardId: RewardId,
      },
    };

    axios(config).then((response) => {
      console.log(response);
      setReports(response.data);
      console.log(response.data);
      setLoading(false);
    });
  };

  const removeReports = () => {
    setLoading(true);
    const config = {
      method: "post",
      url: `${serverUrl}/reports/remove`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        reportsId: deleteViewReports._id,
      },
    };

    axios(config)
      .then((response) => {
        toast("Reports deleted successfully!", darkToast);
      })
      .catch((err) => {
        toast("Something went wrong", darkToast);
      });

    setDeleteViewReports("");
    getReports();
    setLoading(false);
  };

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  const offset = currentPage * perPage;
  const currentPageData =
    reports &&
    reports.slice(offset, offset + perPage).map((report, index) => {
      return !report.username
        .toLowerCase()
        .includes(searchViewReports.toLowerCase()) ? (
        ""
      ) : (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{report.username}</td>

          <td>{report.usermobileNo}</td>
        </tr>
      );
    });
  const pageCount = Math.ceil(reports.length / perPage);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function CustomToolbarGrid() {
    const { data } = useDemoData({
      dataSet: "Employee",
      rowLength: 10,
      maxColumns: 6,
    });

    const columns = [
      { field: "sno", headerName: "S NO", width: 110 },
      {
        headerName: "User Name",
        field: "username",
        width: 200,
      },
      {
        headerName: "mobileNo",
        field: "usermobileNo",
        width: 200,
      },
      {
        headerName: "RewardName",
        field: "RewardName",
        width: 200,
      },
      {
        headerName: "created_at",
        field: "created_at",
        width: 200,
      },
    ];

    let rows = reports;
    rows.forEach((element, i) => {
      rows[i]["id"] = rows[i]["_id"];
      rows[i]["sno"] = i + 1;
    });

    console.log(data);

    return (
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          // {...data}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  return (
    <>
      <Modal
        isOpen={deleteViewReports !== ""}
        style={{
          content: {
            borderRadius: "1rem",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
          overlay: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        }}
      >
        <div className="modalContent">
          <p className="modalTitle">Delete Reports?</p>
          <p className="modalBody">
            Are you sure, want to delete{" "}
            <b>{`${deleteViewReports.username}`}</b> ?
          </p>
          {loading ? (
            <>
              <br />
              <ReactLoading
                type={"spinningBubbles"}
                color={"var(--primaryColor)"}
                width={"2rem"}
                height={"2rem"}
              />
            </>
          ) : (
            <div className="modalButtonsRow">
              <button
                className="cancelbutton"
                onClick={() => setDeleteViewReports("")}
              >
                Cancel
              </button>
              <button
                className="deletebutton"
                onClick={() => {
                  setLoading(true);
                  removeReports();
                  // toast("Can't delete Reports in Demo", darkToast);
                }}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </Modal>

      <div className="content-body">
        <div className="content-title">
          <p>Reports</p>
        </div>
        <div
          style={{
            height: loading ? "70vh" : "",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <ReactLoading
              type={"spinningBubbles"}
              color={"var(--primaryColor)"}
              width={"4rem"}
              height={"4rem"}
            />
          ) : reports.length === 0 ? (
            <p>No reports</p>
          ) : (
            <CustomToolbarGrid />
          )}
        </div>

        <ToastContainer />
      </div>
    </>
  );
}

export default ViewRewardReports;
