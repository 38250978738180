import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Route, useHistory } from "react-router";
import axios from "axios";
import { serverUrl } from "../../Utils/server";
import { toast, ToastContainer, darkToast } from "../../Components/Toast";
import { fontSize } from "@mui/system";
import "./Qrpage.css";
import Modal from "react-modal";
import ReactLoading from "react-loading";

// import QR_Reports from "./QR_Reports";

const Qrpage = (props) => {
  const history = useHistory();
  const [sessionId, setSessionId] = useState(null);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const [qr, setQr] = useState([]);
  const [reportSession, setreportSession] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [active, setActive] = useState(false);
  const [showMore, setshowMore] = useState(false);
  const [selectedReward, setselectedReward] = useState({});
  const [totalRewards, setTotalRewards] = useState([]);
  const [QRCode, setQRCode] = useState([]);

  useEffect(() => {
    console.log(props);
    // getQrImage();
    // getActive();
    getSessions();
    let sessions = localStorage.getItem("sessions") ?? [];
    console.log(JSON.parse(sessions));
    sessions = JSON.parse(sessions);
    let _id = window.location.pathname.replace("/sessions/Qrpage/", "").trim();
    sessions.forEach((item) => {
      if (item._id == _id) {
        setSessionId(_id);
        setQRCode(item.QrImage.map((obj) => obj.QRCode));
        setQr(item.QrImage);
        setTotalRewards(
          item.rewardprofile[0].Rewards.map((obj) => +obj.Quantity)
        );
        setQr(item.QrImage);
      }
    });
  }, []);
  // console.log(totalRewards);
  const total = totalRewards.reduce(function (a, b) {
    return a + b;
  }, 0);
  console.log(total);

  const updateStatusBy_id = (_id, status) => {
    let index = qr.findIndex((item) => item._id == _id);
    console.log(index);
    let allQr = qr;
    allQr[index]["IsActive"] = status;
    console.log(allQr);
    setQr(allQr);
  };

  const getActive = (imagedetails, status) => {
    console.log(imagedetails);

    setLoading(true);
    const config = {
      method: "post",
      url: `${serverUrl}/sessions/ActiveOrInactive`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        qrId: imagedetails._id,
        status: status,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response);
        updateStatusBy_id(imagedetails._id, status);

        setActive(response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getQrImage = () => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${serverUrl}/sessions/get-session-by-id`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };
    console.log(config);

    axios(config)
      .then((response) => {
        console.log(response.data);
        setQr(response.data);
        setLoading(false);
      })
      .catch((err) => {
        toast("Couldn't load Qr Images. Please refresh.", darkToast);
      });
    setLoading(false);
  };

  const getSessions = () => {
    setLoading(true);

    const config = {
      method: "get",
      url: `${serverUrl}/sessions/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };

    axios(config).then((response) => {
      setSessions(response.data);
      console.log(response.data);
      setSessions(response.data);
      localStorage.setItem("sessions", JSON.stringify(response.data));
      setLoading(false);
    });
  };

  function downloadBase64File(base64Data, fileName) {
    const linkSource = base64Data;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <>
      <Modal
        isOpen={showMore}
        style={{
          content: {
            borderRadius: "1rem",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
          overlay: {
            backgroundColor: "rgba(0,0,0,0.7)",
          },
        }}
      >
        <div className="show">
          <div
            style={{
              height: loading ? "70vh" : "",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <table>
              {sessions.map((session, index) => {
                return session._id == sessionId ? (
                  <div className="content-title" key={index}>
                    <div className="style-size">
                      <tr>
                        <th className="rewardname">Reward Name</th>
                        <th className="rewardname">Quantitiy</th>
                      </tr>

                      {session.rewardprofile[0].Rewards.map((reward, index) => (
                        <tr key={index}>
                          <td className="rewardname">{reward?.RewardName}</td>
                          <td>{reward?.Quantity}</td>
                        </tr>
                      ))}
                      <tr>
                        <td className="rewardname">Total</td>
                        <td>{total}</td>
                      </tr>
                    </div>
                  </div>
                ) : null;
              })}
            </table>
          </div>
        </div>

        {loading ? (
          <>
            <br />
            <ReactLoading
              type={"spinningBubbles"}
              color={"var(--primaryColor)"}
              width={"2rem"}
              height={"2rem"}
            />
          </>
        ) : (
          <div className="modalButtonsRow">
            <button className="cancelbutton" onClick={() => setshowMore(false)}>
              Close
            </button>
          </div>
        )}
      </Modal>

      <div>
        {sessions.map((session, index) => {
          return session._id == sessionId ? (
            <div className="content-title" key={index}>
              <p>
                Session Name: <strong>{session.SessionName}</strong>
              </p>
              <p>
                No. of QR's: <strong>{session.RewardCount}</strong>
              </p>
              <p>
                RewardProfile :{" "}
                <strong>
                  <span
                    className="rewardprofilename"
                    onClick={() => {
                      setselectedReward(session);
                      setshowMore(true);
                    }}
                  >
                    <button className="downloadQrview">
                      {session.rewardprofile[0].rewardprofileName}
                    </button>
                  </span>
                </strong>
              </p>
            </div>
          ) : null;
          // <p>Total Qr:{qrCount.RewardCount}</p>
        })}
        {qr.map((image, index) => (
          <div className="qr-code" key={index}>
            <img className="Qrsize" src={image.QrURL} alt="qr" />
            <div>
              {/* <label class="switch">
              <input
                type="checkbox"
                onClick={() => getActive(image, !image.IsActive)}
                checked={image.IsActive}
              />
              <span class="slider round"></span>
            </label> */}

              <button
                className="downloadQr"
                variant="primary"
                onClick={() => {
                  downloadBase64File(image.QrURL, "qr code");
                }}
              >
                Download QR
              </button>

              <button
                className="downloadQrview"
                onClick={() => {
                  history.push(`/sessions/QR_Reports/${QRCode[index]}/`);
                }}
              >
                View
                {/* <FontAwesome
                name="trash"
                // style={{ fontSize: "14px", color: "white" }} 
               /> */}
              </button>
            </div>
            {/* <button
            className="downloadQr"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </button> */}
          </div>
        ))}
      </div>
    </>
  );
};

export default Qrpage;
