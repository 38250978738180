import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Route } from "react-router-dom";
import { ToastContainer, toast, darkToast } from "../../Components/Toast";
import ReactLoading from "react-loading";
import { AddReward } from "./AddReward";
import { serverUrl } from "../../Utils/server";
import axios from "axios";
import FontAwesome from "react-fontawesome";
import Modal from "react-modal";
import EditReward from "./EditReward";
// import ReactPaginate from "react-paginate";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
const perPage = 10;

function Reward() {
  const [searchReward, setSearchRewards] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const [rewards, setRewards] = useState([]);

  const [deleteReward, setDeleteReward] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getReward();
  }, []);

  const getReward = () => {
    setLoading(true);

    const config = {
      method: "get",
      url: `${serverUrl}/rewards/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };

    axios(config).then((response) => {
      setRewards(response.data);
      console.log(response.data);
      setLoading(false);
    });
  };

  const removeReward = () => {
    setLoading(true);

    const config = {
      method: "post",
      url: `${serverUrl}/rewards/remove`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        rewardId: deleteReward._id,
      },
    };

    axios(config)
      .then((response) => {
        toast("Reward deleted successfully!", darkToast);
        setLoading(false);
      })
      .catch((err) => {
        toast("Something went wrong", darkToast);
        setLoading(false);
      });

    setDeleteReward("");
    getReward();
    setLoading(false);
  };

  const getRewardsByType = (type) => {
    if (type === "all") return getRewardsByType();
    setLoading(true);

    const config = {
      method: "post",
      url: `${serverUrl}/rewards/get-rewards-by-type`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        type: type,
      },
    };

    axios(config)
      .then((response) => {
        setSearchRewards(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  const offset = currentPage * perPage;
  const currentPageData = rewards
    .slice(offset, offset + perPage)
    .map((reward, index) => {
      return !reward.RewardType.toLowerCase().includes(
        searchReward.toLowerCase()
      ) ? (
        ""
      ) : (
        <tr key={index}>
          <td>{index + 1}</td>
          <td className="rewardnames">{reward.RewardType}</td>
          {/* <td>
            <img src={events.image} alt="" />
          </td> */}
          <td>{reward.createdOn.toString().split("T")[0]}</td>
          <td>{reward.createdOn.slice(0, 10)}</td> 0.
          <td>
            <button
              onClick={() => {
                history.push(`/reward/edit/${reward._id}`);
              }}
              style={{
                background: "white",
                border: "1px solid var(--primaryColor)",
              }}
            >
              <FontAwesome
                name="pencil"
                style={{
                  fontSize: "1rem",
                  color: "var(--primaryColor)",
                }}
              />
            </button>
          </td>
          <td>
            <button
              className="eventdeletebutton"
              onClick={() => setDeleteReward(reward)}
            >
              <FontAwesome
                name="trash"
                style={{ fontSize: "1rem", color: "white" }}
              />
            </button>
          </td>
        </tr>
      );
    });
  // const pageCount = Math.ceil(rewards.length / perPage);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function CustomToolbarGrid() {
    const { data } = useDemoData({
      dataSet: "Employee",
      rowLength: 10,
      maxColumns: 6,
    });

    const columns = [
      { field: "sno", headerName: "S NO", width: 80 },
      {
        headerName: "Reward Name",
        field: "RewardType",
        width: 200,
      },
      // {
      //   headerName: "Created On",
      //   field: "createdOn",
      //   width: 250,
      // },
      {
        headerName: "Edit",
        field: "edit",
        width: 100,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking
            history.push(`/reward/edit/${params.id}`);

            console.log(params.id);
          };
          return (
            <>
              <button onClick={onClick}>Edit</button>
            </>
          );
        },
      },
      {
        headerName: "Delete",
        field: "delete",
        width: 100,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking
            let reward = rewards.filter((item) => item.id === params.id);
            console.log(reward[0]);
            setDeleteReward(reward[0]);

            console.log(params.id);
          };
          return (
            <>
              <button onClick={onClick}>Delete</button>
            </>
          );
        },
      },
      // {
      //   headerName: "QR code",
      //   field: "qrcode",
      //   width: 100,
      //   renderCell: (params) => {
      //     const onClick = (e) => {
      //       e.stopPropagation();
      //       let session = sessions.filter((item) => item.id === params.id);
      //       if (session[0]?.QrImage.length > 0) {
      //         history.push(`/sessions/Qrpage/${params.id}`);
      //         setQr(session[0]?.QrImage);
      //       } else {
      //         generateQrCode(params.id);
      //       }

      //       console.log(params.id);
      //     };
      //     return (
      //       <>
      //         <button onClick={onClick}>QR</button>
      //       </>
      //     );
      //   },
      // },
      // {
      //   headerName: "Report",
      //   field: "report",
      //   width: 100,
      //   renderCell: (params) => {
      //     const onClick = (e) => {
      //       e.stopPropagation(); // don't select this row after clicking
      //       let session = sessions.filter((item) => item.id === params.id);
      //       history.push(`/sessions/ViewReports/${params.id}`);
      //       setreportSession(session[0]);
      //     };
      //     return (
      //       <>
      //         <button onClick={onClick}>View</button>
      //       </>
      //     );
      //   },
      // },
    ];

    let rows = rewards;
    rows.forEach((element, i) => {
      rows[i]["id"] = rows[i]["_id"];
      rows[i]["sno"] = i + 1;
      // rows[i]["RewardName"] = rows[i]["reward"][0]?.RewardType;
      // rows[i]["CreatedOn"] = rows[i]["reward"][0]?.createdOn;
      // rows[i]["rewardprofileName"] =
      //   rows[i]["rewardprofile"][0]?.rewardprofileName;
      rows[i]["edit"] = i;
    });

    console.log(data);

    return (
      <div style={{ height: 560, width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          // {...data}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }
  return (
    <>
      <Route exact path="/reward">
        <Modal
          isOpen={deleteReward !== ""}
          style={{
            content: {
              borderRadius: "1rem",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
            overlay: {
              backgroundColor: "rgba(0,0,0,0.7)",
            },
          }}
        >
          <div className="modalContent">
            <p className="modalTitle">Delete Reward?</p>
            <p className="modalBody">
              Are you sure, want to delete <b>{`${deleteReward.RewardType}`}</b>{" "}
              ?
            </p>
            {loading ? (
              <>
                <br />
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"var(--primaryColor)"}
                  width={"2rem"}
                  height={"2rem"}
                />
              </>
            ) : (
              <div className="modalButtonsRow">
                <button
                  className="cancelbutton"
                  onClick={() => setDeleteReward("")}
                >
                  Cancel
                </button>
                <button
                  className="deletebutton"
                  onClick={() => {
                    setLoading(true);
                    removeReward();
                    // toast("Can't delete events in Demo", darkToast);
                  }}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </Modal>

        <div className="content-body">
          <div className="content-title">
            <p>Reward</p>
            <button
              className="adduserevent"
              onClick={() => {
                history.push("/Reward/new");
              }}
            >
              + Add New Reward
            </button>
          </div>
          <div className="filter-select">
            {/* <p>Reward Type:</p> */}
            {/* <select onChange={(e) => getRewardsByType(e.target.value)}>
              <option value="all">All</option>
              <option value="reward Name">reward Name</option>
            </select> */}
            {/* <input
              type="text"
              placeholder="Search  reward"
              onChange={(e) => setSearchRewards(e.target.value)} */}
            {/* /> */}
          </div>
          <div
            style={{
              height: loading ? "70vh" : "",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <ReactLoading
                type={"spinningBubbles"}
                color={"var(--primaryColor)"}
                width={"4rem"}
                height={"4rem"}
              />
            ) : rewards.length === 0 ? (
              <p>No Reward</p>
            ) : (
              <CustomToolbarGrid />
            )}
          </div>
          <ToastContainer />
        </div>
      </Route>
      <Route path="/reward/new" component={AddReward} />
      <Route path="/reward/edit/:rewardId" component={EditReward} />
    </>
  );
}

export default Reward;
