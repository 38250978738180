import React, { useState, useEffect } from "react";
import { AddEvents } from "./AddEvents";
import { Route, useHistory } from "react-router-dom";
import { serverUrl } from "../../Utils/server";
import axios from "axios";
import { darkToast, toast, ToastContainer } from "../../Components/Toast";
import ReactLoading from "react-loading";
import FontAwesome from "react-fontawesome";
import Modal from "react-modal";
import EditEvents from "./EditEvents";
import ReactPaginate from "react-paginate";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
const perPage = 10;

function Events() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [channelName, setChannelName] = useState("");
  const [deleteEvents, setDeleteEvents] = useState("");
  const [searchEvent, setSearchEvent] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = () => {
    setLoading(true);

    const config = {
      method: "get",
      url: `${serverUrl}/events/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };

    axios(config).then((response) => {
      setEvents(response.data.reverse());
      console.log(response.data);
      setLoading(false);
    });
  };

  const removeEvents = () => {
    setLoading(true);

    const config = {
      method: "post",
      url: `${serverUrl}/events/remove`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        eventId: deleteEvents._id,
      },
    };

    axios(config)
      .then((response) => {
        toast("Events deleted successfully!", darkToast);
        setLoading(false);
        setDeleteEvents("");
        window.location.reload();
      })
      .catch((err) => {
        toast("Something went wrong", darkToast);
        setLoading(false);
      });
  };
  const getEventsByType = (type) => {
    if (type === "all") return getEvents();
    setLoading(true);

    const config = {
      method: "post",
      url: `${serverUrl}/events/get-events-by-type`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        type: type,
      },
    };

    axios(config)
      .then((response) => {
        setEvents(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  const offset = currentPage * perPage;
  const currentPageData = events
    .slice(offset, offset + perPage)
    .map((event, index) => {
      return !event.EventName.toLowerCase().includes(
        searchEvent.toLowerCase()
      ) ? (
        ""
      ) : (
        <tr key={index}>
          <td>{index + 1}</td>
          <td className="eventnames">{event.EventName}</td>
          {/* <td>
                        <img src={events.image} alt="" />
                      </td> */}
          <td>{event.createdOn.toString().split("T")[0]}</td>
          <td>
            <button
              onClick={() => {
                history.push(`/events/edit/${event._id}`);
              }}
              style={{
                background: "white",
                border: "1px solid var(--primaryColor)",
              }}
            >
              <FontAwesome
                name="pencil"
                style={{
                  fontSize: "1rem",
                  color: "var(--primaryColor)",
                }}
              />
            </button>
          </td>
          <td>
            <button
              className="eventdeletebutton"
              onClick={() => {
                setChannelName(event.EventName);
                setDeleteEvents(event);
              }}
            >
              <FontAwesome
                name="trash"
                style={{ fontSize: "1rem", color: "white" }}
              />
            </button>
          </td>
        </tr>
      );
    });

  const pageCount = Math.ceil(events.length / perPage);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function CustomToolbarGrid() {
    const { data } = useDemoData({
      dataSet: "Employee",
      rowLength: 10,
      maxColumns: 6,
    });

    const columns = [
      { field: "sno", headerName: "S NO", width: 60 },
      {
        headerName: "EventName",
        field: "EventName",
        width: 150,
      },
      // {
      //   headerName: "createdOn",
      //   field: "createdOn",
      //   width: 200,
      // },
      {
        headerName: "Edit",
        field: "edit",
        width: 100,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking
            history.push(`/events/edit/${params.id}`);

            console.log(params.id);
          };
          return (
            <>
              <button onClick={onClick}>Edit</button>
            </>
          );
        },
      },
      {
        headerName: "Delete",
        field: "delete",
        width: 100,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking
            let even = events.filter((item) => item.id === params.id);
            console.log(even[0]);
            setDeleteEvents(even[0]);

            console.log(params.id);
          };
          return (
            <>
              <button onClick={onClick}>Delete</button>
            </>
          );
        },
      },
    ];

    let rows = events;
    rows.forEach((element, i) => {
      rows[i]["id"] = rows[i]["_id"];
      rows[i]["sno"] = i + 1;
      rows[i]["edit"] = i;
    });

    console.log(data);

    return (
      <div style={{ height: 560, width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          // {...data}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  return (
    <>
      <Route exact path="/events">
        <Modal
          isOpen={deleteEvents !== ""}
          style={{
            content: {
              borderRadius: "1rem",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
            overlay: {
              backgroundColor: "rgba(0,0,0,0.7)",
            },
          }}
        >
          <div className="modalContent">
            <p className="modalTitle">Delete Events?</p>
            <p className="modalBody">
              Are you sure, want to delete <b>{`${deleteEvents.EventName}`}</b>{" "}
              ?
            </p>
            {loading ? (
              <>
                <br />
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"var(--primaryColor)"}
                  width={"2rem"}
                  height={"2rem"}
                />
              </>
            ) : (
              <div className="modalButtonsRow">
                <button
                  className="cancelbutton"
                  onClick={() => setDeleteEvents("")}
                >
                  Cancel
                </button>
                <button
                  className="deletebutton"
                  onClick={() => {
                    setLoading(true);
                    console.log(events);
                    // setDeleteEvents(events);
                    removeEvents();
                    // toast("Can't delete events in Demo", darkToast);
                  }}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </Modal>
        <div className="content-body">
          <div className="content-title">
            <p>Events</p>
            <button
              className="adduserevent"
              onClick={() => {
                history.push("/events/new");
              }}
            >
              + Add New events
            </button>
          </div>
          {/* <div className="filter-select">
            <p>Event type:</p>
            <select onChange={(e) => getEventsByType(e.target.value)}>
              <option value="all">All</option>
              <option value="name">ADMIN</option>
            </select>
            <input
              type="text"
              placeholder=" Search by event"
              onChange={(e) => setSearchEvent(e.target.value)}
            />
          </div> */}
          <div
            style={{
              height: loading ? "70vh" : "",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <ReactLoading
                type={"spinningBubbles"}
                color={"var(--primaryColor)"}
                width={"4rem"}
                height={"4rem"}
              />
            ) : events.length === 0 ? (
              <p>No Events</p>
            ) : (
              <CustomToolbarGrid />
            )}
          </div>
          {/* <table>
                <tr>
                  <th>No.</th>
                  <th>Name</th>
                  <th>Created On</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr> */}
          {/* {events.map((event, index) => {
                  return !event.EventName.toLowerCase().includes(
                    searchEvent.toLowerCase()
                  ) ? (
                    ""
                  ) : ( */}
          {/* <tr key={index}> */}
          {/* <td>{index + 1}</td> */}
          {/* <td className="eventnames">{event.EventName}</td> */}
          {/* <td>
                        <img src={events.image} alt="" />
                      </td> */}
          {/* <td>{event.createdOn.toString().split("T")[0]}</td> */}
          {/* <td> */}
          {/* <button */}
          {/* onClick={() => { */}
          {/* history.push(`/events/edit/${event._id}`); */}
          {/* }} */}
          {/* style={{
                            background: "white",
                            border: "1px solid var(--primaryColor)",
                          }}
                        > */}
          {/* <FontAwesome */}
          {/* name="pencil" style= */}
          {/* {{
                  fontSize: "1rem",
                  color: "var(--primaryColor)",
                }}
                // /> */}
          {/* </button> */}
          {/* </td> */}
          {/* <td> */}
          {/* <button
                          className="eventdeletebutton"
                          onClick={() => {
                            setChannelName(event.EventName);
                            setDeleteEvents(event);
                          }}
                        >
                          <FontAwesome
                            name="trash"
                            style={{ fontSize: "1rem", color: "white" }}
                          />
                        </button> */}
          {/* </td> */}
          {/* </tr> */}
          {/* ); */}
          {/* })} */}
          <ToastContainer />
        </div>
      </Route>
      <Route path="/events/new" component={AddEvents} />
      <Route path="/events/edit/:eventId" component={EditEvents} />
    </>
  );
}

export default Events;
