import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Route } from "react-router-dom";
import { ToastContainer, toast, darkToast } from "../../Components/Toast";
import ReactLoading from "react-loading";
import { serverUrl } from "../../Utils/server";
import axios from "axios";
import FontAwesome from "react-fontawesome";
import Modal from "react-modal";
import EditUser from "./EditUser";
import { AddUser } from "./AddUser";
import ReactPaginate from "react-paginate";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
const perPage = 5;

function Users() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [sentmails, setSendMails] = useState([]);
  const [deleteUser, setDeleteUser] = useState("");
  const [approveUser, setApproveUser] = useState("");
  const [searchUserEmail, setSearchUserEmail] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    setLoading(true);

    const config = {
      method: "get",
      url: `${serverUrl}/users/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then((response) => {
        setUsers(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getUsersByType = (type) => {
    if (type === "all") return getUsers();
    setLoading(true);

    const config = {
      method: "post",
      url: `${serverUrl}/users/get-users-by-type`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        type: type,
      },
    };

    axios(config)
      .then((response) => {
        setUsers(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const removeUser = () => {
    setLoading(true);

    const config = {
      method: "post",
      url: `${serverUrl}/users/remove`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        userId: deleteUser._id,
      },
    };

    axios(config)
      .then((response) => {
        toast("User deleted successfully!", darkToast);
        setLoading(false);
        setDeleteUser("");
        window.location.reload();
      })
      .catch((err) => {
        toast("Something went wrong", darkToast);
        setLoading(false);
      });
  };

  const Sentmail = () => {
    setLoading(true);

    const config = {
      method: "post",
      url: `${serverUrl}/users/Send-Mail`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        mailId: sentmails.email,
      },
    };

    axios(config)
      .then((response) => {
        toast("Mail sent successfully!", darkToast);
        setLoading(false);
        setSendMails("");
        window.location.reload();
      })
      .catch((err) => {
        toast("Something went wrong", darkToast);
        setLoading(false);
      });
  };

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  const offset = currentPage * perPage;
  const currentPageData = users
    .slice(offset, offset + perPage)
    .map((user, index) => {
      return !user.name.toLowerCase().includes(searchUserEmail.toLowerCase()) &&
        !user.email.toLowerCase().includes(searchUserEmail.toLowerCase()) ? (
        ""
      ) : (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>
            <div className="user-list-tile" onClick={() => {}}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-person-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg>
                <img src={user.profilePic} alt="" />
              </div>
              <p className="usernamelist">{user.name}</p>
            </div>
          </td>
          <td>
            <a className="mailcolor" href={"mailto:" + user.email}>
              {user.email}
            </a>
          </td>
          <td>
            {user.type[0].toUpperCase() +
              user.type.toString().substring(1, user.type.length)}
          </td>
          <td>{user.joinedOn.split("T")[0]}</td>
          <td>
            <button
              onClick={() => {
                history.push(`/users/edit/${user._id}`);
              }}
              style={{
                background: "white",
                border: "1px solid var(--primaryColor)",
              }}
            >
              <FontAwesome
                name="pencil"
                style={{
                  fontSize: "1rem",
                  color: "var(--primaryColor)",
                }}
              />
            </button>

            <button
              className="eventdeletebutton"
              onClick={() => setDeleteUser(user)}
            >
              <FontAwesome
                name="trash"
                style={{ fontSize: "1rem", color: "white" }}
              />
            </button>

            <button
              className="approvebutton"
              onClick={() => setApproveUser(user)}
            >
              <FontAwesome
                name="fa-thin fa-check"
                style={{ fontSize: "1rem", color: "white" }}
              />
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
<path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> */}
            </button>
          </td>
        </tr>
      );
    });
  const pageCount = Math.ceil(users.length / perPage);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function CustomToolbarGrid() {
    const { data } = useDemoData({
      dataSet: "Employee",
      rowLength: 10,
      maxColumns: 6,
    });

    const columns = [
      { field: "sno", headerName: "S NO", width: 70 },
      {
        headerName: "Name",
        field: "name",
        width: 120,
      },
      {
        headerName: "Email",
        field: "email",
        width: 250,
      },
      {
        headerName: "type",
        field: "type",
        width: 110,
      },
      {
        headerName: "joinedOn",
        field: "joinedOn",
        width: 120,
      },
      {
        headerName: "Edit",
        field: "edit",
        width: 100,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking
            history.push(`/users/edit/${params.id}`);

            console.log(params.id);
          };
          return (
            <>
              <button onClick={onClick}>Edit</button>
            </>
          );
        },
      },
      {
        headerName: "Delete",
        field: "delete",
        width: 100,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking
            let user = users.filter((item) => item.id === params.id);
            console.log(user[0]);
            setDeleteUser(user[0]);

            console.log(params.id);
          };
          return (
            <>
              <button onClick={onClick}>Delete</button>
            </>
          );
        },
      },
    ];

    let rows = users;
    rows.forEach((element, i) => {
      rows[i]["id"] = rows[i]["_id"];
      rows[i]["sno"] = i + 1;
      rows[i]["edit"] = i;
    });

    console.log(data);

    return (
      <div style={{ height: 560, width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          // {...data}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  return (
    <>
      <Route exact path="/users">
        <Modal
          isOpen={deleteUser !== ""}
          style={{
            content: {
              borderRadius: "1rem",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
            overlay: {
              backgroundColor: "rgba(0,0,0,0.7)",
            },
          }}
        >
          <div className="modalContent">
            <p className="modalTitle">Delete User?</p>
            <p className="modalBody">
              Are you sure, want to delete{" "}
              <b>
                {`${deleteUser.name}`} ({deleteUser.email})
              </b>{" "}
              ?
            </p>
            {loading ? (
              <>
                <br />
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"var(--primaryColor)"}
                  width={"2rem"}
                  height={"2rem"}
                />
              </>
            ) : (
              <div className="modalButtonsRow">
                <button
                  className="cancelbutton"
                  onClick={() => setDeleteUser("")}
                >
                  Cancel
                </button>
                <button
                  className="deletebutton"
                  onClick={() => {
                    setLoading(true);
                    removeUser();
                    // toast("Can't delete User in Demo", darkToast);
                  }}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </Modal>

        <Modal
          isOpen={approveUser !== ""}
          style={{
            content: {
              borderRadius: "1rem",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
            overlay: {
              backgroundColor: "rgba(0,0,0,0.7)",
            },
          }}
        >
          <div className="modalContent">
            <p className="modalTitle">Approve User?</p>
            <p className="modalBody">
              Are you sure, want to Approve{" "}
              <b>
                {`${approveUser.name}`} ({approveUser.email})
              </b>{" "}
              ?
            </p>
            {loading ? (
              <>
                <br />
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"var(--primaryColor)"}
                  width={"2rem"}
                  height={"2rem"}
                />
              </>
            ) : (
              <div className="modalButtonsRow">
                <button
                  className="cancelbutton"
                  onClick={() => setApproveUser("")}
                >
                  Cancel
                </button>
                <button
                  className="deletebutton"
                  onClick={() => {
                    // setLoading(true);
                    Sentmail();
                    // toast("Can't delete User in Demo", darkToast);
                  }}
                >
                  Approve
                </button>
              </div>
            )}
          </div>
        </Modal>
        <div className="content-body">
          <div className="content-title">
            <p>Users</p>
            <button
              className="adduserbutton"
              onClick={() => {
                history.push("/users/new");
              }}
            >
              + Add New User
            </button>
          </div>
          <div
            style={{
              height: loading ? "70vh" : "",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <ReactLoading
                type={"spinningBubbles"}
                color={"var(--primaryColor)"}
                width={"4rem"}
                height={"4rem"}
              />
            ) : (
              <CustomToolbarGrid />
            )}
          </div>
          <ToastContainer />
        </div>
      </Route>
      <Route path="/users/new" component={AddUser} />
      <Route path="/users/edit/:userId" component={EditUser} />
    </>
  );
}

export default Users;
