import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Route } from "react-router";
import { serverUrl } from "../../Utils/server";
import axios from "axios";
import FontAwesome from "react-fontawesome";
import { toast, ToastContainer, darkToast } from "../../Components/Toast";
import Modal from "react-modal/lib/components/Modal";
import ReactPaginate from "react-paginate";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
const perPage = 5;

// import { Modal, Button } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";

//import { currencySymbol } from "../../Utils/currency";

function Reports() {
  const [reports, setReports] = useState("");
  const [searchReports, setSearchReports] = useState("");

  const [loading, setLoading] = useState(false);

  const [deleteReports, setDeleteReports] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getReports();
  }, []);

  const getReports = () => {
    setLoading(true);

    const config = {
      method: "get",
      url: `${serverUrl}/reports/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };

    axios(config).then((response) => {
      setReports(response.data);
      console.log(response.data);
      setLoading(false);
    });
  };

  const removeReports = () => {
    setLoading(true);
    const config = {
      method: "post",
      url: `${serverUrl}/reports/remove`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      data: {
        reportsId: deleteReports._id,
      },
    };

    axios(config)
      .then((response) => {
        toast("Reports deleted successfully!", darkToast);
      })
      .catch((err) => {
        toast("Something went wrong", darkToast);
      });

    setDeleteReports("");
    getReports();
    setLoading(false);
  };

  // const getReportsByType = (type) => {
  //   if (type === "all") return getReports();
  //   setLoading(true);

  //   const config = {
  //     method: "post",
  //     url: `${serverUrl}/reports/get-reports-by-type`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `JWT ${localStorage.getItem("token")}`,
  //     },
  //     data: {
  //       type: type,
  //     },
  //   };

  //   axios(config)
  //     .then((response) => {
  //       // setReports(response.data);
  //       console.log(response.data);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  // };

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  const offset = currentPage * perPage;
  const currentPageData =
    reports &&
    reports.slice(offset, offset + perPage).map((report, index) => {
      return !report.username
        .toLowerCase()
        .includes(searchReports.toLowerCase()) ? (
        ""
      ) : (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{report.username}</td>

          <td>{report.usermobileNo}</td>

          <td>{report.sessionName}</td>

          <td>{report.EventName}</td>
          <td>{report.rewardProfileName}</td>

          <td>
            <button
              className="eventdeletebutton"
              onClick={() => setDeleteReports(reports)}
            >
              <FontAwesome
                name="trash"
                style={{ fontSize: "1rem", color: "white" }}
              />
            </button>
          </td>
        </tr>
      );
    });
  const pageCount = Math.ceil(reports.length / perPage);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function CustomToolbarGrid() {
    const { data } = useDemoData({
      dataSet: "Employee",
      rowLength: 10,
      maxColumns: 6,
    });

    const columns = [
      { field: "sno", headerName: "S NO", width: 110 },
      {
        headerName: "User Name",
        field: "username",
        width: 200,
      },
      {
        headerName: "mobileNo",
        field: "usermobileNo",
        width: 200,
      },
      // {
      //   headerName: "sessionName",
      //   field: "sessionName",
      //   width: 150,
      // },
      // {
      //   headerName: "EventName",
      //   field: "EventName",
      //   width: 150,
      // },
      {
        headerName: "RewardName",
        field: "RewardName",
        width: 200,
      },
      // {
      //   headerName: "Delete",
      //   field: "delete",
      //   width: 150,
      //   renderCell: (params) => {
      //     const onClick = (e) => {
      //       e.stopPropagation(); // don't select this row after clicking
      //       let reports = reports.filter((item) => item.id === params.id);
      //       console.log(reports[0]);
      //       setDeleteReports(reports[0]);

      //       console.log(params.id);
      //     };
      //     return (
      //       <>
      //         <button onClick={onClick}>Delete</button>
      //       </>
      //     );
      //   },
      // },
    ];

    let rows = reports;
    rows.forEach((element, i) => {
      rows[i]["id"] = rows[i]["_id"];
      rows[i]["sno"] = i + 1;
    });

    console.log(data);

    return (
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          // {...data}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  return (
    <>
      <Route exact path="/reports">
        <Modal
          isOpen={deleteReports !== ""}
          style={{
            content: {
              borderRadius: "1rem",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
            overlay: {
              backgroundColor: "rgba(0,0,0,0.7)",
            },
          }}
        >
          <div className="modalContent">
            <p className="modalTitle">Delete Reports?</p>
            <p className="modalBody">
              Are you sure, want to delete <b>{`${deleteReports.username}`}</b>{" "}
              ?
            </p>
            {loading ? (
              <>
                <br />
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"var(--primaryColor)"}
                  width={"2rem"}
                  height={"2rem"}
                />
              </>
            ) : (
              <div className="modalButtonsRow">
                <button
                  className="cancelbutton"
                  onClick={() => setDeleteReports("")}
                >
                  Cancel
                </button>
                <button
                  className="deletebutton"
                  onClick={() => {
                    setLoading(true);
                    removeReports();
                    // toast("Can't delete Reports in Demo", darkToast);
                  }}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </Modal>

        <div className="content-body">
          <div className="content-title">
            <p>Reports</p>
          </div>
          {/* <div className="filter-select">
            <p>Reports Type:</p>
            <select onChange={(e) => getReportsByType(e.target.value)}>
              <option value="all">All</option>
              <option value="session Name">session Name</option>
            </select>
            <input
              type="text"
              placeholder="Search Session name"
              onChange={(e) => setSearchReports(e.target.value)}
            />
          </div> */}
          <div
            style={{
              height: loading ? "70vh" : "",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <ReactLoading
                type={"spinningBubbles"}
                color={"var(--primaryColor)"}
                width={"4rem"}
                height={"4rem"}
              />
            ) : reports.length === 0 ? (
              <p>No reports</p>
            ) : (
              <CustomToolbarGrid />
            )}
          </div>
          <ToastContainer />
        </div>
      </Route>
    </>
  );
}

export default Reports;
